
import React from 'react'
import photo_data from 'src/_data/fr/domainfr.json'
import { sortAlbums } from 'src/utils.js'
import Carousel from 'nuka-carousel';
import Img from 'gatsby-image'
import Container from '@material-ui/core/Container'
import tw, { styled } from 'twin.macro'
import { createGlobalStyle } from "styled-components"
import { Header } from 'src/components/Header'
import { Footer } from 'src/components/Footer'
import {Link} from 'gatsby'


const GlobalStyle = createGlobalStyle`
.prev-btn{
  display: none;
  @media screen and (min-width: 800px){
    display: block;
  }
}
.next-btn{
  display: none;
  @media screen and (min-width: 800px){
    display: block;
  }
}
.italic{
font-style:italic;
a{text-decoration: underlined;
&:hover{font-weight: bold;}}
}
body{margin:0}
p{
  font-family: 'Cormorant Garamond', serif;
}
.slider-control-bottomcenter{
  bottom: -3rem!important;
}
.gatsby-image-wrapper{
  display: block!important;
  margin: auto;
}
.text-center{
  text-align: center;
}
.page-content p{
	font-family: 'Cormorant Garamond', serif;
	font-size: 120%;
	text-align: justify;
    margin: 1em 1.5em 1em 1.5em;
}

.page-content{
  margin-top: -8rem!important;
	position: relative;
	background-color: #fff;
	width: 90%;
	max-width: 800px;
	margin: auto;
	z-index: 2;
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	padding: 2em 0 5em 0;
}

@media (max-width: 400px){
	.page-content p{
	  font-size: 100%;
}
}
@media (max-width: 900px) {
	.page-content p{
  	font-size: 110%;
}
}

@media (min-width: 901px) { 
  .page-content p{
	  font-size: 120%;
  }
}
.page-title-wrapper {
  color: red;
  h2{
    text-align: center;
	font-family: 'Cinzel', serif;
	font-size: 2em;
	font-weight: normal;
	color: #8d929c;
    margin: 0.5em 0 1.5em 0;
}}
.page-content .text-center{
  text-align: center;
}
.slider-slide {
  :focus {outline: none!important;}
}


.bottom-page-image-wrapper{
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
	width: 100%;
	/*height: 100%;*/
}

.bottom-page-image{
	align-self: flex-end;
	width: 100%;
    background: linear-gradient(ghostwhite 10%, rgba(0, 0, 0, 0) 35%);
}

.bottom-page-image img{
	position: relative;
	width: 100%;
	z-index: -1;
}

.top-page-image-wrapper{
	position: absolute;
	z-index: -5;
	top: 0;
	width: 100%;
}

.top-page-image{
	width: 100%;
    background: linear-gradient(rgba(248, 0, 0, 0) 42%, ghostwhite 73%, ghostwhite);
}

.top-page-image img{
	position: relative;
	width: 100%;
	z-index: -1;
}
`


const CarouselWrapper = styled.div`
width: 100%;
  
@media screen and (min-width: 800px){
  width: 750px;
}
  margin: auto;
  margin-bottom: 3rem;
`



const SlideWrapper = styled.div`
 ${tw`mb-8`}
`

const ImageWrapper = styled.div`
  height: calc(100% - 3rem);
`
const componentName = styled(Img)`
     picture > img {
    height: auto !important;
    }
    `
const Domain = ({ location, data }) => {

  
  const nodes = data.allFile.nodes
  
  const sortedAlbums = sortAlbums(nodes, photo_data)
  const bottom = nodes.filter(n => n.relativePath.match('0011'))[0].childImageSharp.fluid
  const top = nodes.filter(n => n.relativePath.match('0010'))[0].childImageSharp.fluid
  return (
    <>
      <GlobalStyle />

      <Container>
        <Header location={location} />
        <div className='page-content'>
          <div className='page-title-wrapper centered-text'>
            <h2>Le Domaine</h2>
          </div>
          <CarouselWrapper>
            <Carousel  disableEdgeSwiping={sortedAlbums.filter(a => a.album === 1).length > 1 ? true : true} heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
nextButtonClassName: "next-btn",             
prevButtonText: '<',
prevButtonClassName:  "prev-btn",          
nextButtonStyle: {display
: sortedAlbums.filter(a => a.album === 1).length > 1 ? '' : 'none'},
  prevButtonStyle: {display: sortedAlbums.filter(a => a.album === 1).length > 1 ? '' : 'none'},
  pagingDotsStyle: {display: sortedAlbums.filter(a => a.album === 1).length > 1 ? '' : 'none'}
              }}>
              {sortedAlbums.filter(a => a.album === 1).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid}   imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>
          <p>Le <span className="italic"><Link to={location.pathname.match(/\/fr\//) ? '/fr/domain/park' : '/en/domain/park'}>parc </Link></span>actuel, qui occupe environ 25 acres, comprend presque tous les éléments caractéristiques d'un parc Choulot : une allée de tilleuls, un anneau de platanes des plus imposants, de nombreux bosquets d'arbres aux teintes et aux auvents contrastés et des points de vue soigneusement conçus à travers les zones boisées. Un nouvel arboretum a été créé, dont la plantation est encore en cours.</p>
          <CarouselWrapper>
            <Carousel  disableEdgeSwiping={sortedAlbums.filter(a => a.album === 2).length > 1 ? true : true} heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
nextButtonClassName: "next-btn",             
prevButtonText: '<',
prevButtonClassName:  "prev-btn",          
nextButtonStyle: {display
: sortedAlbums.filter(a => a.album === 2).length > 1 ? '' : 'none'},
  prevButtonStyle: {display: sortedAlbums.filter(a => a.album === 2).length > 1 ? '' : 'none'},
  pagingDotsStyle: {display: sortedAlbums.filter(a => a.album === 2).length > 1 ? '' : 'none'}
              }}>
              {sortedAlbums.filter(a => a.album === 2).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid}   imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>

          <p>Il y a deux petits lacs, le plus grand ayant une petite île sur laquelle se trouve une charmante petite maison de cygne entourée de cyprès des marais.</p>
          <CarouselWrapper>
            <Carousel  disableEdgeSwiping={sortedAlbums.filter(a => a.album === 3).length > 1 ? true : true} heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
nextButtonClassName: "next-btn",             
prevButtonText: '<',
prevButtonClassName:  "prev-btn",          
nextButtonStyle: {display
: sortedAlbums.filter(a => a.album === 3).length > 1 ? '' : 'none'},
  prevButtonStyle: {display: sortedAlbums.filter(a => a.album === 3).length > 1 ? '' : 'none'},
  pagingDotsStyle: {display: sortedAlbums.filter(a => a.album === 3).length > 1 ? '' : 'none'}
              }}>
              {sortedAlbums.filter(a => a.album === 3).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid}   imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>

          <p>Le vaste <span className="italic"><Link to={location.pathname.match(/\/fr\//) ? '/fr/domain/potager' : '/en/domain/potager'}>jardin clos</Link></span> a conservé ses haies de buis mûres, qui séparent les places et les allées, et comprend un potager et un jardin physique. D'autres places ont été plantées d'arbres fruitiers. Les bâtiments de la ferme qui donnent sur le jardin offrent des espaces dédiés au stockage des fruits et des pommes de terre, aujourd'hui restaurés et remis en service. Il y a une serre, qui est en usage productif, bien que sa serre adjacente n'ait pas encore été refaite. L'ensemble du jardin offre une merveilleuse atmosphère de tranquillité combinée à un sens subtil de l'utilité.</p>

          <CarouselWrapper>
            <Carousel disableEdgeSwiping={sortedAlbums.filter(a => a.album === 4).length > 1 ? true : true} heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
nextButtonClassName: "next-btn",             
prevButtonText: '<',
prevButtonClassName:  "prev-btn",          
nextButtonStyle: {display
: sortedAlbums.filter(a => a.album === 4).length > 1 ? '' : 'none'},
  prevButtonStyle: {display: sortedAlbums.filter(a => a.album === 4).length > 1 ? '' : 'none'},
  pagingDotsStyle: {display: sortedAlbums.filter(a => a.album === 4).length > 1 ? '' : 'none'}
              }}>
              {sortedAlbums.filter(a => a.album === 4).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid}   imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>

          <p> <span><Link to={location.pathname.match(/\/fr\//) ? '/fr/domain/prosliers' : '/en/domain/prosliers'}>Maison longère</Link></span> probablement construit pour le gestionnaire du vignoble, datant également de 1670 mais restauré plus récemment.</p>
          <CarouselWrapper>
            <Carousel  disableEdgeSwiping={sortedAlbums.filter(a => a.album === 7).length > 1 ? true : true} heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
              nextButtonClassName: "next-btn",
              prevButtonText: '<',
              prevButtonClassName: "prev-btn",
              nextButtonStyle: {
                display
                  : sortedAlbums.filter(a => a.album === 7).length > 1 ? '' : 'none'
              },
              prevButtonStyle: { display: sortedAlbums.filter(a => a.album === 7).length > 1 ? '' : 'none' },
              pagingDotsStyle: { display: sortedAlbums.filter(a => a.album === 7).length > 1 ? '' : 'none' }
            }}>
              {sortedAlbums.filter(a => a.album === 7).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>


          <p>La jolie <span className="italic"><Link to={location.pathname.match(/\/fr\//) ? '/fr/domain/courtyard' : '/en/domain/courtyard'}>cour de la ferme </Link></span> comprend un pigeonnier proéminent, dans lequel l'échelle tournante tourne encore sur simple pression d'un doigt. Les autres bâtiments comprennent un grand grenier, des écuries, diverses granges, un atelier et un garage
	</p>

          <CarouselWrapper>
            <Carousel  disableEdgeSwiping={sortedAlbums.filter(a => a.album === 5).length > 1 ? true : true} heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
nextButtonClassName: "next-btn",             
prevButtonText: '<',
prevButtonClassName:  "prev-btn",          
nextButtonStyle: {display
: sortedAlbums.filter(a => a.album === 5).length > 1 ? '' : 'none'},
  prevButtonStyle: {display: sortedAlbums.filter(a => a.album === 5).length > 1 ? '' : 'none'},
  pagingDotsStyle: {display: sortedAlbums.filter(a => a.album === 5).length > 1 ? '' : 'none'}
              }}>
              {sortedAlbums.filter(a => a.album === 5).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid}   imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>

          <p>La <span className="italic"><Link to={location.pathname.match(/\/fr\//) ? '/fr/domain/chapel' : '/en/domain/chapel'}>chapelle</Link></span>, dans une ancienne laiterie du XVIIe siècle, charmamment décorée par le dernier comte de Savigny, contient les armoiries de nombreuses familles locales. Elle a été bénie en 1920, par l'autorité d'un décret papal.
</p>

          <CarouselWrapper>
            <Carousel  disableEdgeSwiping={sortedAlbums.filter(a => a.album === 6).length > 1 ? true : true} heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
nextButtonClassName: "next-btn",             
prevButtonText: '<',
prevButtonClassName:  "prev-btn",          
nextButtonStyle: {display
: sortedAlbums.filter(a => a.album === 6).length > 1 ? '' : 'none'},
  prevButtonStyle: {display: sortedAlbums.filter(a => a.album === 6).length > 1 ? '' : 'none'},
  pagingDotsStyle: {display: sortedAlbums.filter(a => a.album === 6).length > 1 ? '' : 'none'}
              }}>
              {sortedAlbums.filter(a => a.album === 6).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid}   imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>

          <p>Le <span className="italic"><Link to={location.pathname.match(/\/fr\//) ? '/fr/domain/chateau' : '/en/domain/chateau'}>château</Link></span>, qui était abandonné depuis plus de 20 ans, a été progressivement restauré et est maintenant de nouveau utilisé comme maison familiale. Au bout du jardin clos de murs se trouve une maison de vigneron restaurée qui est actuellement louée.</p>
          <p> Le parc n'est généralement pas ouvert au public, mais il est ouvert de temps en temps sur rendez-vous.</p>


        </div>


      </Container>
      <div className='bottom-page-image-wrapper'>
        <div className='bottom-page-image'>
          <Img fluid={bottom}
            imgStyle={{ objectFit: 'cover' }}
          />
        </div>
      </div>
      <div className='top-page-image-wrapper'>
        <div className='top-page-image'>
          <Img fluid={top}
            imgStyle={{ objectFit: 'cover' }}
          />
        </div>
      </div>
      <Footer />
    </>
  )
}

export const data = graphql`
  query domainfr {
          allFile(filter: {relativeDirectory: {eq: "domain"}}) {
          nodes {
          id
        childImageSharp {
          fixed(width: 600, quality: 100) {
          ...GatsbyImageSharpFixed
        }
          fluid(maxWidth: 1600, quality: 100) {
          aspectRatio
          ...GatsbyImageSharpFluid
        }
        }
        relativePath
      }
    }
  }
  `

export default Domain

